html,
body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
		sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

.NavigationLink {
	display: inline-flex;
	width: 100%;
	padding: 5px;
	align-items: center;
	line-height: 16px;
}

.NavigationLink svg {
	font-size: 20px;
	margin-right: 10px;
}
.NavigationLink:focus {
	box-shadow: none !important;
}

.NavigationLink:hover,
.NavigationLink.active {
	background-image: linear-gradient(29deg, #bcc866 0%, #689c90) !important;
	color: #fff !important;
	text-decoration: none !important;
	border-radius: 4px;
}
